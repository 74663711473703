import './App.css';
import CartProvider from './context/CartContext';
import Rutas from './routes/routes.jsx';

function App() {

  window.location.replace('https://mupuviajes.com/');

  return (
    <div className='dadContainer'>
      {/* <CartProvider>
        <Rutas/> 
      </CartProvider> */}
      <h1>Cargando...</h1>
    </div>
  );
}

export default App;